import React from 'react';
import FeedbackForm from './Components/FeedbackForm';
import VideoBackground from './Components/VideoComponent';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Stack } from '@mui/material';
import getConfig from './helpers/getConfig';
import { Helmet } from 'react-helmet';


const hostEnv = getConfig();

function HealthCheck() {
    return <div className='app-container' style={{ color: 'white'}}>OK</div>;
}

function HomePage() {
  return (
    <Stack className="app-container" sx={{ height: "100vh" }}>
      <div className="logo">
        <img src={hostEnv.logo} alt="" />
      </div>
      {
        hostEnv.entity === "CARS24" && (
          <VideoBackground />
        )
      }
      <FeedbackForm />
    </Stack>
  );
}

function App() {

    const defaultTitle = `${hostEnv.entity}`;
    const title = hostEnv.title || defaultTitle;
    const favicon = hostEnv.favicon;
    return (
        <Router>
          <Helmet>
            <title>{hostEnv.entity} {title}</title>
            <link rel="icon" href={favicon} />
          </Helmet>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path="/public/health" element={<HealthCheck />} />
            </Routes>
        </Router>
    );
}

export default App;
