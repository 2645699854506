import React from 'react';

const VideoBackground = () => {
  return (
    <div className='video-container'>
      <video autoPlay loop muted>
        <source src="https://cdn.24c.in/qa/new-car-cms/root/2024/05/09/4baf943e-1eec-46d4-b74b-5b59fa3abc8f-dance.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoBackground;