import React, { useState, useEffect } from 'react';
import { Button, Typography, Rating, Box, Stack, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import './FeedbackForm.css';
import getConfig from '../helpers/getConfig';

const FormComponent = () => {
    const [formQuestions, setFormQuestions] = useState([]);
    const [feedbackIdentifier, setFeedbackIdentifier] = useState();
    const [submitted, setSubmitted] = useState(false); // Track if the form is submitted
    const [loading, setLoading] = useState(true); // Track if the data is loading
    const [open, setOpen] = useState(false); // Track if the dialog is open
    const urlParams = new URLSearchParams(window.location.search);

    const reqId = urlParams.get('req_id');
    const applicantName = urlParams.get('name');

    const hostEnv = getConfig();

    const labels = {
        "1": 'Useless',
        "10": 'Excellent+',
    };

    function getLabelText(value) {
        const newValue = value.toString();
        return `${newValue} Star${newValue !== "1" ? 's' : ''}, ${labels[newValue]}`;
    }

    useEffect(() => {
        axios.get(`${hostEnv.insightBaseUrl}/config/rating/hrms/${hostEnv.feedback_enpoint}`)
            .then((response) => {
                const sortedQuestions = Object.values(response.data?.data?.initialQuestions).sort((a, b) => a.sequence - b.sequence);
                setFormQuestions(sortedQuestions);
                setFeedbackIdentifier(response.data?.data?.ssId);
                setLoading(false); // Set loading to false after data is loaded
            })
            .catch((error) => {
                console.error('Error fetching form questions:', error);
                setLoading(false); // Set loading to false even if there is an error
            });
    }, [hostEnv.insightBaseUrl, hostEnv.feedback_enpoint]);

    const initialValues = {};
    const optionIds = {};
    const optionWeights = {}; // Object to store option weights

    const validationSchemaFields = {};

    Object.values(formQuestions).forEach(question => {
        initialValues[question.id] = '';
        optionIds[question.id] = [];
        optionWeights[question.id] = {};
        question.options.forEach(option => {
            optionIds[question.id].push(option.id);
            optionWeights[question.id][option.id] = option.weightage;
        });
        validationSchemaFields[question.id] = Yup.number()
            .required('This field is required')
            .min(1, 'This field is required'); // Ensures a rating is selected
    });

    const validationSchema = Yup.object().shape(validationSchemaFields);

    const feedbackResponse = {
        "ssId": `${feedbackIdentifier}`,
        "identifierId": `${reqId}`,
        "identifierType": "FEEDBACK",
        "feedbackGivenFor": `${hostEnv.entity}`,
        "feedbackGivenForEntityType": "INTERVIEW",
        "answer": [],
        "feedbackGivenByMetadata": {
            "externalId": "sdfdsf-34234-sdfsdf-234234"
        },
        "feedbackGivenBy": `${reqId}_${applicantName}`, /*alias:userId*/
        "feedbackGivenByEntityType": "APPLICANT", /*alias:userType*/
    };

    const handleSubmit = async (values) => {
        let answerList = [];
        Object.keys(values).forEach(questionId => {
            const selectedOptionId = optionIds[questionId][values[questionId] - 1];
            const selectedOptionWeightage = optionWeights[questionId][selectedOptionId]; // Fetch corresponding weightage
            answerList.push({
                "optionId": selectedOptionId,
                "weightage": selectedOptionWeightage,
                "comment": "",
                "metadata": {},
                "questionId": questionId
            });
        });

        feedbackResponse.answer = answerList;

        const headers = {
            "x-channel-name": "hrms_web",
            "token": 'Bearer token'
        };

        try {
            await axios.post(`${hostEnv.insightBaseUrl}/feedback`, feedbackResponse, {
                headers: headers
            });
            setSubmitted(true); // Set submitted to true upon successful submission
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const validateForm = (values) => {
        const errors = {};
        Object.keys(values).forEach(key => {
            if (values[key] === '' || values[key] === null) {
                errors[key] = 'This field is required';
            }
        });
        return errors;
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validate={validateForm}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, values, setFieldValue, errors, touched, validateForm, handleSubmit }) => (
                <div className='form-wrapper'>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Form className='form-card'>
                            {submitted ? (
                                <Typography variant="h5" component="h2" className='survey-title' fontFamily="Roboto" fontWeight={500}>
                                    Thanks for submitting your feedback. We appreciate your time and effort.
                                </Typography>
                            ) : (
                                <Stack spacing={1}>
                                    <Typography variant="h4" component="h2" className='survey-title' fontFamily="Roboto" fontWeight={500}>Tune-up Talk</Typography>
                                    {Object.values(formQuestions).map((question, index) => (
                                        <div className='question-box' key={question.id}>
                                            <Typography fontFamily="Roboto" fontWeight={500}>
                                                {question.name} <span style={{ color: 'red' }}>*</span>
                                            </Typography>
                                            <Box mb={3} borderColor="transparent">
                                                <Field name={question.id}>
                                                    {({ field }) => (
                                                        <>
                                                            <Rating
                                                                {...field}
                                                                name={question.id}
                                                                value={values[question.id]}
                                                                onChange={(event, newValue) => {
                                                                    setFieldValue(question.id, newValue);
                                                                }}
                                                                size="large"
                                                                precision={1}
                                                                max={10}
                                                                getLabelText={getLabelText}
                                                            />
                                                            {errors[question.id] && touched[question.id] && (
                                                                <Typography color="error">{errors[question.id]}</Typography>
                                                            )}
                                                        </>
                                                    )}
                                                </Field>
                                            </Box>
                                        </div>
                                    ))}
                                    <div style={{ textAlign: "center", width: "100%" }}>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                validateForm(values).then((errors) => {
                                                    if (Object.keys(errors).length > 0) {
                                                        setOpen(true); // Open the dialog if there are validation errors
                                                    } else {
                                                        handleSubmit(); // Proceed with form submission
                                                    }
                                                });
                                            }}
                                            sx={{ maxWidth: "50%" }}
                                        >
                                            That's it, I am officially out of fuel
                                        </Button>
                                    </div>
                                </Stack>
                            )}
                        </Form>
                    )}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>{"Form Incomplete"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please fill all responses before submitting the form.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </Formik>
    );
};

export default FormComponent;
