const appConfig = {
    qa: {
        insightBaseUrl: "http://insight360.qac24svc.dev/api/v2",
        entity: "CARS24",
        feedback_enpoint: 'interview-feedback',
        logo: "https://fastly-production.24c.in/cars24/seo/static/1_20230830_1693395013.png",
        favicon: '/favicon.ico',
        title: 'Cariot NPS Feedback',
    },
    prod: {
        insightBaseUrl: "https://insight360.c24.tech/api/v2",
        entity: "CARS24",
        feedback_enpoint: 'interview-feedback',
        logo: "https://fastly-production.24c.in/cars24/seo/static/1_20230830_1693395013.png",
        favicon: '/favicon.ico',
        title: 'Cariot NPS Feedback',
    },
    qa_cariot: {
      insightBaseUrl: "http://insight360.qac24svc.dev/api/v2",
      entity: "CARIOT",
      feedback_enpoint: 'interview-fd-cariot',
      logo: "https://cariotauto.com/wp-content/uploads/2023/09/New-Project-4.png",
      favicon: '/favicon-cariot.ico',
      title: 'Cariot NPS Feedback',
    },
    prod_cariot: {
      insightBaseUrl: "https://insight360.c24.tech/api/v2",
      entity: "CARIOT",
      feedback_enpoint: 'interview-fd-cariot',
      logo: "https://cariotauto.com/wp-content/uploads/2023/09/New-Project-4.png",
      favicon: '/favicon-cariot.ico',
      title: 'Cariot NPS Feedback',
    }
  };
  
  const getConfig = () => {
    const env = process.env.REACT_APP_HOST_ENV || 'production';
  
    if (env.toLowerCase() === "production") {
      return appConfig.prod;
    } else if(env.toLowerCase() === "qa") {
      return appConfig.qa;
    } else if (env.toLowerCase() === "production_cariot") {
      return appConfig.prod_cariot;
    }else if (env.toLowerCase() === "qa_cariot") {
      return appConfig.qa_cariot;
    }
  };
  
  export default getConfig;